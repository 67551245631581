html {
    scroll-behavior: auto;
}

body {
    font-family: 'Roboto', sans-serif;
}

.textLight {
    font-weight: 300;
}

.textBold {
    font-weight: 500;
}

.fontSmall {
    font-size: .95em;
}

.maxWidthHome {
    max-width: 85%
}

.maxWidth1200 {
    max-width: 1200px
}


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/*================= latest_blog_area css =============*/

.container.ventajas {
    max-width: 1200px
}

.single-recent-blog-post {
    margin-bottom: 30px;
}

.single-recent-blog-post .thumb {
    overflow: hidden;
}

.single-recent-blog-post .thumb img {
    -webkit-transition: all 0.7s linear;
    -o-transition: all 0.7s linear;
    transition: all 0.7s linear;
}

.single-recent-blog-post .details {
    padding-top: 30px;
}

.single-recent-blog-post .details .sec_h4 {
    line-height: 24px;
    padding: 10px 0px 13px;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.single-recent-blog-post .details .sec_h4:hover {
    color: #52c5fd;
}

.single-recent-blog-post .date {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.single-recent-blog-post:hover img {
    -webkit-transform: scale(1.23) rotate(10deg);
    -ms-transform: scale(1.23) rotate(10deg);
    transform: scale(1.23) rotate(10deg);
}

.tags .tag_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #eeeeee;
    display: inline-block;
    padding: 1px 18px;
    text-align: center;
    color: #222222;
}

.tags .tag_btn:before {
    background: #f3c300;
}

.tags .tag_btn+.tag_btn {
    margin-left: 2px;
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1rem;
}

.rowForm {
    margin-bottom: 30px;
}

@media (min-width: 1400px) {
    .main-container.container {
        min-height: calc(100vh - 80px);
        max-width: 100%;
        padding: 0;
    }
}

@media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
         max-width: 1400px;
    }
}

@media (max-width: 992px) {
  nav{display: flex;
    background: #2a93eb;
    flex-direction: column;}
    .col-md-6 {
        flex: 0 0 auto;
        width: 100% !important;
    }
    .col-md-5 {
        flex: 0 0 auto;
        width: 100% !important;
    }
    .col-md-7 {
        flex: 0 0 auto;
        width: 100% !important;
    }
    .col-md-4 {
        flex: 0 0 auto;
        width: 100% !important;
    }
    .col-md-7 {
        flex: 0 0 auto;
        width: 100% !important;
    }
}

.text_editors {
    height: 30px
}

.list-group li {
    background: white;
}

.list-group li:hover {
    background: wheat;
}

*/ li:focus {
    outline: solid 1px green;
    border-color: red;
    color: red;
}

select:focus {
    border: 1px solid rgb(214, 246, 255);
}

select {
    padding: 10px;
}

ul {
    position: absolute;
    z-index: 9;
}

ul.legal {
    position: relative;
}

.fadein {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 5s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.textDisabled {
    opacity: 0.5
}

.checkBig {
    width: 2em;
    height: 2em
}
.checkMiddle {
    width: 1.2em;
    height: 1.2em;
}

#rutas,
#creaRuta,
#misRutas,
#login,
#resetPass,
#ruta,
#editarRuta,
#registro,
#login,
#avisolegal,
#miPerfil,
#pisoInfo {
    padding-top: 140px;
}

.form-select:disabled {
    background-color: rgb(247, 247, 247);
}

.bgBlue {
    background: #0275d8;
    color: white;
}

.titulo {
    font-weight: 500;
    font-size: 1.1em;
}

.checkBig+label {
    padding-top: 8px;
    margin-left: 10px;
}

.btBackGreen {
    background: #11b6c2;
    border: none
}

.btBackGreen:hover {
    background: #0275d8;
    border: none
}

.buscarRutas {
    width: 100%;
    padding: 12px;
}

.btn {
    border-radius: .25rem;
}
.btnEdit{
  height:30px;
  background-color:#11b6c2;
  border: none;
  border-radius: 0.2em;
  color: white;
  font-size: .9em;
}
.btn-edit{height:40px}
.btn-main {
    color: #fff;
    font-size: 18px;
    padding: 0px 5%;
    display: inline-block;
    background-color: #0275d8;
}

.btn-main:hover,
.btn-main:focus {
    background-color: #11b6c2;
    border-color: #11b6c2;
    color: #fff;
}

.btn-close_ {
    color: #fff;
    font-size: 18px;
    /* padding: 10px 10px; */
    display: inline-block;
    background-color: #0275d8;
}

.btn-close_:hover,
.btn-close_:focus {
    background-color: #11b6c2;
    color: #fff;
}

.btn-editar {
    color: #fff;
    font-size: 16px;
    padding: 5px 20px;
    display: inline-block;
    background-color: #0275d8;
}

.btn-editar:hover,
.btn-editar:focus {
    background-color: #11b6c2;
    color: #fff;
}

.btn-primary:hover {
    background-color: #11b6c2;
    color: #fff;
    border: 1px solid #11b6c2
}

.btn-verSolicitud {
    color: #fff;
    font-size: 16px;
    padding: 5px 20px;
    display: inline-block;
    background-color: #11b6c2;
}

.btn-verSolicitud:hover,
.btn-verSolicitud:focus {
    background-color: #11b6c2;
    color: #fff;
}

.btn-enviar {
    color: #fff;
    font-size: 16px;
    padding: 7px 30px;
    display: inline-block;
    background-color: #0275d8;
}

.btn-enviar:hover,
.btn-enviar:focus {
    background-color: #11b6c2;
    color: #fff;
}

.btn-pop {
    background-color: #11b6c2;
    border-color: #11b6c2;
    min-width: 100px;
}

.btn-pop:hover {
    background-color: #0275d8;
    border-color: #0275d8;
}

.btn-outline-primary {
    color: #0275d8;
    border-color: #0275d8;
}

.btn-check:checked+.btn-outline-primary {
    color: #fff;
    border-color: #0275d8;
    background: #0275d8;
}
.btn-check+.btn.btn-main:hover {
  background-color: #11b6c2;
  border-color: #11b6c2;
  color: #fff;
}
.btn-check+.btn.btn-main {
  padding:12px 20px;
}
p.centros{
  margin-bottom: 0px;
}

li.list-group-item:hover {
    background: aliceblue;
}

@media (max-width: 993px) {
    .col-md-3 {
        flex: 0 0 auto;
        width: 100%;
    }
}

h1 {
    font-size: 1.9em;
    border-bottom: 1px solid rgb(177, 177, 177);
    padding-bottom: 20px;
}

body {
    background: url('src\assets\images\bg_main.jpg') 0 -150px;
    background-size: cover;
}

body {
    background: #ffffff
}

.contentRounded {
    max-width: 100%;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    justify-content: center;
}


/* .show {
    opacity: 1;
    transition: opacity 1s;
 }

 .hide {
    height:0;
    transition: opacity 1s;
 } */


/* .show {
    opacity: 1;
    transition: opacity 1s;
 }

 .hide {
    opacity: 0.5;
    transition: opacity 1s;
 } */

.hideUserText {
    opacity: 0;
}

.hideButtonUsuario {
    display: none
}

.showButtonUsuario {
    display: block
}

.active {
    display: block!important
}

.disable {
    display: none!important
}

.bold {
    font-weight: 500;
}

.w-35 {
    width: 32%;
}

.extraInfo {
    font-size: .9em;
    font-weight: 400;
    padding: 0;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.w-90 {
    width: 90%
}

.w-40 {
    width: 40%;
}

.w-300px {
    width: 300px
}


/* .footer{ height: 60px; position:relative; bottom: 0; width: 100%; } */

.fontSize110 {
    font-size: 110%
}

.card-body {
    padding: 2.3rem!important;
}

.card {
    border: 1px solid rgba(0, 0, 0, .250);
}

.textBlack {
    color: black !important;
}

input[type=text],
input[type=number],
input[type=password],
button,
select {
    height: 50px
}

@media (max-width: 600px) {
    .main-container {
        padding: 0px!important;
    }
    /* .container{ padding: 10px!important;} */
    #rutasContainer,
    #crearContainer,
    #misrutasContainer,
    #registroContainer {
        padding: 15px!important
    }
    #rutas,
    #creaRuta,
    #misRutas,
    #login,
    #resetPass,
    #ruta,
    #editarRuta,
    #registro,
    #login {
        padding-top: 110px;
    }
    input[type=text],
    input[type=password],
    button {
        height: 50px
    }
    .contentRounded {
        padding: 0px;
    }
    .rowForm {
        margin-bottom: 15px;
    }

}


/*quiota el color de fondo del input d recueprar pass*/

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: white !important;
    color: -internal-light-dark(black, white) !important;
}

#CybotCookiebotDialogNav {
    border-bottom: 1px solid #D6D6D6;
    position: relative;
    width: 100%;
    height: 55px!important;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    background-color: transparent;
    border: 1px solid #0275d8!important;
    border-radius: 0.25em;
    color: #141414;
    display: flex;
    font-weight: 600;
    justify-content: center;
    padding: 1em;
    user-select: none;
    width: 100%;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: #0275d8!important;
    border-color: #0275d8!important;
    color: #ffffff;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-bottom: 1px solid #0275d8!important;
    color: #0275d8!important;
}

.osano-cm-button--type_save {
    display: none
}

.osano-cm-window__dialog {
    font-size: 0.8em;
}

.osano-cm-button {
    height: 40px;
    font-size: 12px;
    font-weight: 500;
}

.osano-cm-dialog--type_bar .osano-cm-dialog__list {
    display: none!important
}

.osano-cm-link {
    color: white!important
}

.osano-cm-info--position_right {
    padding: 20px
}

.osano-cm-view__list.osano-cm-list {
    display: contents
}

.osano-cm-disclosure__list {
    background-color: white
}

.osano-cm-window__widget {
    display: none
}

.osano-cm-content__drawer-links.osano-cm-drawer-links {
    margin-bottom: 20px
}

.osano-cm-drawer-links {
    margin: .5em 0;
    display: block;
}

.osano-cm-link {
    color: white !important;
    display: block;
    margin-top: 5px
}


/* .osano-cm-save.osano-cm-view__button.osano-cm-button{display:none} */


/* OSANO COOKIES */

@media (max-width: 480px) {
    /* OSANO COOKIES */
    .osano-cm-button--type_save {
        display: none
    }
    .osano-cm-window__dialog {
        font-size: 12px;
        /* height: 90%; */
    }
    .osano-cm-dialog--type_bar .osano-cm-dialog__list {
        display: none!important
    }
}


/* @media (min-width: 992px){
.misrutasRow.row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 100%;
}}
@media (max-width: 300px){
.btn-misrutas{margin-left: 0px!important; width: 100%;; margin-top: 10px;}

} */


 @media (min-width:993px) and (max-width:1200px){
  .btn-misrutas{margin-left: 0px!important; width: 100%;; margin-top: 10px;}
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }




.ng-image-slider .ng-image-slider-container .main {
  overflow: hidden;
  position: absolute;
  height: 400px!important;
  width: 100%;
}
.containerSlider{height: 400px;}
.pisoDesc{font-size: 14px; font-weight: 400; line-height: 1.65em;}
.pisoInfo{font-size: 15px; font-weight: 500; line-height: 1.65em; border:1px solid #ccc; padding:5px 10px; border-radius:4px; margin-top:10px}
.pisoPrecio{font-size: 22px; font-weight: 500; line-height: 1.65em; border:1px solid #ccc;  padding:5px 10px; border-radius:4px; margin-top:10px}

.arrow{
  position:relative;
  width:30px;
  height: 30px;
  cursor:pointer;
/*   border:solid 1px white; */
  transition:0.5s;
  overflow:hidden;
}

.arrow:hover{
/*  animation:borderAni 5s cubic-bezier(0, 0.6, 1, 0.4); */

}
.arrow:after{
  position:absolute;
  display:block;
  content:"";
  color:#0275d8;
  width: 20px;
  height: 17px;
/*   border:solid 1px;  */
  top:-1px;
  border-bottom:solid 2px;
  transform:translatex(4px);
}

.arrow:before{
  position:absolute;
  display:block;
  content:"";
  color:#0275d8;
  width: 12px;
  height: 12px;
/*   border:solid 1px;  */
  border-top:solid 2px;
  border-left:solid 2px;
  top:50%;
  left:2px;
  transform-origin:0% 0%;
  transform:rotatez(-45deg);

}
.ng-image-slider .ng-image-slider-container .main .main-inner .img-div {

  box-shadow:none!important;

}
.ng-image-slider .ng-image-slider-container .main .next, .ng-image-slider .ng-image-slider-container .main .prev {

  color: grey!important;
  text-decoration: none!important;
}
.ng-image-slider .ng-image-slider-container .main .next, .ng-image-slider .ng-image-slider-container .main .prev {

  right: 4px!important;

}
.ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .next, .ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .prev {

  text-decoration:none

}
.ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .prev {

  padding-top: 2px!important;
  padding-right: 2px!important;


}
.ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .next{

  padding-top: 2px!important;
  padding-right: 0px!important;


}

@media (max-width: 600px) {

  .ng-image-slider .ng-image-slider-container .main {

    height: 220px!important;

}
.containerSlider {
  height: 200px!important;
}

#pisoInfo .card{
  border:none;
  border-bottom:1px solid #ccc
}



}

.contPisoImg:not(.mispisos){
  height:190px;
  overflow:hidden
}
@media (max-width: 1400px) {

.contPisoImg:not(.mispisos){
  height:160px;
  overflow:hidden
}
}
@media (max-width: 1200px) {

  .contPisoImg:not(.mispisos){
    height:130px;
    overflow:hidden
  }
  }
@media (max-width: 900px) {
  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div img.ratio, .ng-image-slider .ng-image-slider-container .main .main-inner .img-div video.ratio {

    max-width: 99.4%!important;

}
.contPisoImg:not(.mispisos){
  height:200px;
  overflow:hidden
}
}
@media (min-width: 768px) and (max-width: 999px) {

.contPisoImg:not(.mispisos){
  height:300px;
  overflow:hidden
}
}
@media (max-height: 800px) {
#pisoInfo {
  padding-top: 120px;
}
}


